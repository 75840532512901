<template>
  <div>
    <SimpleTableForm
      :id="id"
      title="Create Nursery"
      :table-config="tableConfig"
      :table-config-categories="tableConfigCategories"
      :return-route="adminRoute"
      :server-type="serverType"
      @created="$emit('created')"
    />
  </div>
</template>

<script>

import SimpleTableForm from '@/components/form/SimpleTableForm.vue'
import { nurseryTypeForm } from './constants'

export default {
  name: 'EcosystemForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      tableConfig: nurseryTypeForm,
      tableConfigCategories: {},
      serverType: 'nurseries',
      adminRoute: '/admin/nurseries'
    }
  },
  async created () {
    this.tableConfigCategories['nurseryCategories'] = (await this.$axios.get('/nursery_categories')).data
    this.loading = false
  }
}
</script>
