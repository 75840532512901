export const nurseryFields = [
  {
    name: 'lowCarbonFactor',
    label: 'Low Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'n'
  },
  {
    name: 'medianCarbonFactor',
    label: 'Median Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'n'
  },
  {
    name: 'highCarbonFactor',
    label: 'High Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'n'
  },
  {
    name: 'embodiedCarbonCost',
    label: 'Embodied Carbon Cost',
    suffix: 'kgCO₂e/M^3',
    prefix: 'n'
  },
  {
    name: 'mass',
    label: 'Mass',
    suffix: 'kg',
    prefix: 'n'
  },
  {
    name: 'massPerUnit',
    label: 'Mass / Unit',
    suffix: 'kg/ea',
    prefix: 'n'
  }
]

export const nurseryTypeForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'ccNurseryId',
    label: 'CC Nursery Id'
  },
  {
    name: 'nurseryCategories',
    elementType: 'select',
    multiple: true,
    label: 'Nursery Category'
  },
  ...nurseryFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type === 'plant'
}
